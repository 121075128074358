<template>
  <div class="filter">
    <div class="filter-form">
      <el-form :inline="true" :model="form" class="form-inline">
        <el-form-item style="max-width: 200px" v-if="selection">
          <el-select v-model="form.filterTarget">
            <slot></slot>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input v-model="form.filterValue" :placeholder="placeholder"></el-input>
        </el-form-item>
        <el-form-item style="max-width: 200px">
          <el-select v-model="sortForm.criterions">
            <el-option :label="$t('Sort by newer')" value="Newer"></el-option>
            <el-option :label="$t('Sort by older')" value="Older"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">{{$t('Search')}}</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'ItemFilter',
  props: {
    default: String,
    placeholder: String,
    selection: {
      type: Boolean,
      default: true,
    },
  },
  data(){
    let filterTarget = '';
    if(this.default){
      filterTarget = this.default;
    }
    return {
      sortForm: {
        'criterions': 'Newer',
      },
      form: {
        'filterTarget': filterTarget,
        'filterValue': '',
      }
    };
  },
  methods:{
    search(){
      this.$emit('search', this.form);
    },
  },
  computed: mapState({
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style scoped lang="scss">
.filter{
  padding-left: 20px;
}
</style>
